const JSON_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;

export const useReviver = () => {
    return (_, value) => {
        if (typeof value === "string" && JSON_DATE_REGEX.test(value)) {
            return new Date(value);
        }
        return value;
    };
}
