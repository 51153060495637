import DubrinkError from "./Error.js";
import { useReviver } from "@/helpers/useReviver.js";
import { useUserStore } from '@/stores/user.store.js';

const reviver = useReviver();

export const useFetch = () => {
    return {
        request: async (method = "GET", url, payload, upload = false) => {
            const { csrfToken } = useUserStore();
            const fullUrl = import.meta.env.VITE_API_URL + url;
            const response = await fetch(fullUrl, {
                method: method,
                credentials: "include",
                headers: {
                    "x-csrf-token": csrfToken,
                    ...!upload && { "Content-Type": "application/json" }
                },
                ...(method !== "GET" && !upload) && { body: JSON.stringify(payload) },
                ...(method === "POST" && upload) && { body: payload },
            });
            const text = await response.text();
            const data = JSON.parse(text, reviver);
            if(!response.ok) {
                throw new DubrinkError(data.msg, response.status);
            }
            return data;
        },
        getSearchParams: (filter) => filter ? `?${ filter }` : "",
    }
}

