import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import Gleap from 'gleap';

export const useUserStore = defineStore('user', () => {
    const user = ref(null);
    const setUser = (data) => {
        Gleap.identify(data.cuid, { name: data.firstName, email: data.email, phone: data.phone, company: data.companyName }, data.gleapHash);
        user.value = data;
    }
    const unsetUser = () => Gleap.clearIdentity();
    const isCompanyType = (type) => user.value.company.type === type;
    const showTour = ref(true);
    const isLoggedIn = computed(() => !!user.value);
    const csrfToken = computed(() => user.value?.csrfToken);
    const hasCompletedSetup = computed(() => !!user.value?.company);
    return { user, csrfToken, setUser, unsetUser, isLoggedIn, isCompanyType, hasCompletedSetup, showTour };
});